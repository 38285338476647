import React from "react";
import { HomeLandingPageOne } from "./HomeLandingPage";
import { Footer } from "../../components/Footer";
import { OurClients } from "../Home/OurClients";
import { Partnes } from "../Home/Partnes";
import { ScrollTopButton } from "../../components/ScrollTopButton";
import { WhatsAppButton } from "../../components/WhatsAppButton";

import { useParams } from "react-router-dom";
import jsonData from "../../data/data.json";

import styles from "./styles.module.css";

export function LandingPage() {
  const params = useParams();
  const product = jsonData.Services.find(
    (prod) => prod.idName === params?.idName
  );

  return (
    <div className={styles.container}>
      <HomeLandingPageOne product={product} />
      <OurClients style={{ padding: "4rem 0px" }} />
      <Partnes />
      <Footer style={{ color: "#fff" }} />
      <ScrollTopButton />
      <WhatsAppButton system={product?.name} />
    </div>
  );
}
