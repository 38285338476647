import React, { useEffect, useState } from "react";
import waveSvg from "../../../assets/wave.svg";
import networkSvg from "../../../assets/intro-bg-white.png";

import styles from "./styles.module.css";
import { InfoCard } from "./InfoCard";
import { Slider } from "infinite-react-carousel/lib";
import debounce from "../../../utils/debouce";

const CARD_WIDH = 320;

export function HomeLandingPageOne({ product }) {
  const [slidesToShow, setSlidesToShow] = useState(
    Math.floor((window.innerWidth * 0.9) / CARD_WIDH)
  );

  const { name, text, informations, idName, productType } = product;

  const productImage = require(`../../../assets/products/${idName}.png`);

  const whatsAppLink = new URL(
    "https://api.whatsapp.com/send?phone=5563991000057"
  );
  whatsAppLink.searchParams.append(
    "text",
    `Olá, desejo testar o sistema ${name}`
  );


  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      let slides = Math.floor((window.innerWidth * 0.9) / CARD_WIDH);
      setSlidesToShow(slides > 4 ? 4 : slides < 1 ? 1 : slides);
    }, 1000);
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.homeContent}>
          <div className={styles.textContent}>
            <h1 className={styles.title}>{name}</h1>
            <p className={styles.description}>{text}</p>

            <div className={styles.homeButtonWrapper}>
              <a className={styles.ctaButton} href={whatsAppLink}>
                Teste Grátis
              </a>
            </div>
          </div>
          <div className={styles.imgWrapper}>
            <img
              className={`${
                productType === 1 ? styles.mainImageType1 : styles.mainImageType2
              }`}
              src={productImage}
              alt=""
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Slider
            autoplay
            arrows={false}
            slidesToShow={slidesToShow > 3 ? 3 : slidesToShow}
          >
            {informations.map((info) => (
              <InfoCard
                key={info.title}
                title={info.title}
                description={info.description}
              />
            ))}
          </Slider>
        </div>
      </div>
      <img src={networkSvg} className={styles.networkSvg} alt="" />
      
      <div className={styles.bgWrapper}>
        <img src={waveSvg} className={styles.backgroundSvg} alt="" />
      </div>
    </div>
  );
}
