import React from "react";
import { FaMagic, FaComments, FaBullhorn } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";

import styles from "./styles.module.css";

const icons = {
  magic: <FaMagic size={36} />,
  comments: <FaComments size={36} />,
  bullhorn: <FaBullhorn size={36} />,
  group: <HiUserGroup size={36} />,
};

export const Feature = ({ feature }) => {
  const handleIcon = (iconName = "") => {
    iconName = iconName.split("-").at(1);
    return icons[iconName];
  };
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>{handleIcon(feature.icon)}</div>
      <div>
        <h2 className={styles.title}>{feature.title}</h2>
        <p className={styles.description}>{feature.text}</p>
      </div>
    </div>
  );
};
