import React, { useEffect, useState } from "react";
import { Product } from "./Product";
import Slider from "infinite-react-carousel";

import styles from "./styles.module.css";

import dataJson from "../../../data/data.json";

import waveSvg from "../../../assets/wave.svg";
import unitechSystems from "../../../assets/systems-unitech.png";
import backgroundNetwork from "../../../assets/intro-bg-white.png";
import circles from "../../../assets/circles.png";
import menuCheff from "../../../assets/menucheff.png";
import uniCheff from "../../../assets/unicheff.png";
import logoUnitech from "../../../assets/logoUnitech.png";
import debounce from "../../../utils/debouce";

export function Products() {
  const systems = dataJson.Services;
  const [slidesToShow, setSlidesToShow] = useState(
    Math.floor((window.innerWidth * 0.9) / 270)
  );

  const handleIcon = (iconName) => {
    return iconName.includes("unicheff") ? uniCheff : menuCheff;
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      let slides = Math.floor((window.innerWidth * 0.9) / 270);
      setSlidesToShow(slides > 4 ? 4 : slides < 1 ? 1 : slides);
    }, 1000);
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <img
        src={backgroundNetwork}
        className={styles.backgroundNetwork}
        alt=""
      />
      <img src={waveSvg} className={styles.backgroundSvg} alt="" />
      <div className={styles.content}>
        <div className={styles.mainContent}>
          <div className={styles.textContent}>
            <img src={logoUnitech} alt="" className={styles.homeLogo} />
            <p className={styles.homeDescription}>
              Sistemas de automação comercial para seu estabelecimento. Somos
              especilistas em bares e restaurantes com mais de 18 anos de
              mercado.
            </p>
            <div className={styles.homeButtons}>
              <a
                className={styles.homeButton}
                href="https://api.whatsapp.com/send?phone=5563991000057&text=Ola%2C%20desejo%20saber%20mais%20sobre%20o%20sistema"
              >
                Saber mais
              </a>
              <a
                className={styles.homeButton}
                href="https://api.whatsapp.com/send?phone=5563991000057&text=Ola%2C%20desejo%20testar%20o%20sistema"
              >
                Teste Grátis
              </a>
            </div>
          </div>

          <div className={styles.imageWrapper}>
            <img src={circles} className={styles.circles} alt="" />
            <img src={unitechSystems} className={styles.systems} alt="" />
          </div>

          <div className={styles.footer}>
            <div className={styles.footerContent}>
              <Slider
                autoplay
                arrows={false}
                slidesToShow={slidesToShow > 4 ? 4 : slidesToShow}
              >
                {systems.map((system) => (
                  <Product
                    key={system.name}
                    system={system}
                    icon={handleIcon(system.img)}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
