import React from "react";
import { Feature } from "./Feature";

import attend from "../../../assets/attend.png";
import data from "../../../data/data.json";

import styles from "./styles.module.css";

export function Features() {
  const features = data.Features;

  return (
    <div className={styles.container}>
      <h1>Nosso atendimento</h1>
      <div className={styles.content}>
        <div className={styles.features}>
          {features.map((feature) => (
            <Feature
              key={`${feature.title}-${feature.icon}`}
              feature={feature}
            />
          ))}
        </div>
        <div className={styles.imageWrapper}>
          <img src={attend} alt="" className={styles.sectionImage} />
          <a
            style={{ color: "transparent", position: "absolute" }}
            href="https://br.freepik.com/fotos-gratis/operador-confiante-de-call-center-conversando-com-o-cliente_6882148.htm#query=atendimento&position=12&from_view=search&track=sph"
          >
            Imagem de pch.vector no Freepik
          </a>
        </div>
      </div>
    </div>
  );
}
