import React from "react";
import { Navbar } from "../../components/Navbar";
import { Outlet } from "react-router-dom";

import styles from './styles.module.css'

export function NavBarLayout() {
    return (
        <div className={styles.container} id="layout">
            <Navbar />
            <Outlet/>
        </div>
    )
}