import React from "react";
import GoogleMapReact from "google-map-react";
import { Marker } from "./Marker";

import styles from "./styles.module.css";

const CitiesProps = [
  {
    name: "",
    local: {
      lat: 0,
      lon: 0,
    },
  },
];

export default function Maps({ cities = CitiesProps, center, zoom }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
          center={center}
          zoom={zoom}
        >
          {cities.map((city) => (
            <Marker
              key={city.name}
              lat={city.local.lat}
              lng={city.local.lon}
              text={city.name}
            />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  );
}
