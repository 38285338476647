import React from "react";
import { FaWhatsapp } from "react-icons/fa";

import styles from "./styles.module.css";

export function WhatsAppButton({ system = "" }) {
  const whatsappUrl = new URL(
    "https://api.whatsapp.com/send?phone=5563991000057"
  );
  const message = `Olá, desejo testar o sistema ${system}`.trim();

  whatsappUrl.searchParams.append("text", message);

  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <span className={styles.text}>Posso te ajudar?</span>
      </div>

      <a href={whatsappUrl} className={styles.whatsappButton}>
        <FaWhatsapp color="#ffffff" className={styles.icon} />
      </a>
    </div>
  );
}
