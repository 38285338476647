import React from 'react';

import styles from './styles.module.css';

import tonolucro from '../../../assets/partnes/tonolucro.png';
import ifood from '../../../assets/partnes/ifood.png';
import pix from '../../../assets/partnes/pix.png';
import tef from '../../../assets/partnes/tef.png';
import pagarme from '../../../assets/partnes/pagarme.png';

const partnesImages = [
  tonolucro,
  ifood,
  pagarme,
  'https://storage.googleapis.com/partnerhub_partner_badges_prod/D_622f7fb8e122860019bc53da.png',
  tef,
  pix,
];

export function Partnes() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Parceiros e Integrações</h1>
        <div className={styles.partnesImagesWrapper}>
          {partnesImages.map((image, i) => (
            <div key={i} className={styles.partnerImage}>
              <img src={image} alt="" style={{ width: '100%' }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
