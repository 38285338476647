import React from 'react';
import styles from './styles.module.css';

export function Employee({ employee }) {
  const image = employee.Imagem.replace('../../../', '');
  const imageUrl ='https://unidesk.unitechsistemas.com.br/' + image;
 
    return (
      <div className={styles.employee}>
        <img src={imageUrl} alt="" className={styles.employeeImg} />
        <h2 className={styles.employeeName}>{employee.Nome}</h2>
      </div>
    );
  };