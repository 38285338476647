import React from "react";
import styles from "./styles.module.css";

export function Footer({ ...rest }) {
  return (
    <div className={styles.container} {...rest}>
      <p className={styles.text}>
        &copy; {1900 + new Date().getYear()} - Desenvolvido por Unitech Sistemas
      </p>
    </div>
  );
}
