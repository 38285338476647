import React from "react";

import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { LandingPage } from "./pages/LandingPage";
import { Locations } from "./pages/Locations";
import { Privacy } from "./pages/Privacy";
import { NavBarLayout } from "./Layout/NavbarLayout";

export function Router() {
  return (
    <Routes>
      <Route path="/" element={<NavBarLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/produto/:idName" element={<LandingPage />} />
        <Route path="/locais" element={<Locations />} />
        <Route path="/privacy" element={<Privacy />} />
      </Route>
      <Route path="/landing/:idName" element={<LandingPage />} />
    </Routes>
  );
}
