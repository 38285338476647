import React, { useEffect, useState } from "react";
import menucheff from "../../../services/menucheff";

import styles from "./styles.module.css";

const Client = ({ client }) => {
  const logo = `https://appdelivery.menucheff.com/${client.Logomarca}`;

  if (String(client.NomeFantasia?.toLowerCase()).includes("teste")) {
    return;
  }
  return (
    <div className={styles.clientContent}>
      <div className={styles.clientImgWrapper}>
        <img className={styles.clientImg} src={logo} alt="" />
      </div>
      <div className={styles.clientInfo}>
        <h3>{client.NomeFantasia}</h3>
      </div>
    </div>
  );
};

export function OurClients({ ...rest }) {
  const [lists, setLists] = useState({ firstList: [], secondList: [] });

  useEffect(() => {
    const filterClientsWithImage = (client, idx, arr) => {
      const hasLogo = client.Logomarca !== "" && client.Logomarca?.length < 999;
      const exists =
        idx !==
        arr.findIndex((cli) => cli.NomeFantasia === client.NomeFantasia);
      return hasLogo && !exists;
    };

    async function handleEmpresas() {
      try {
        const req = menucheff.get(`Empresa/listarAleatorio.php`);

        const responses = await Promise.allSettled([req, req, req, req]);

        const clients = responses
          .filter((res) => res.status === "fulfilled")
          .map((res) => res?.value?.data)
          .flat()
          .filter(filterClientsWithImage);

        const half = Math.floor(clients.length / 2);
        const firstList = clients.slice(0, half);
        const secondList = clients.slice(half);

        setLists({ firstList, secondList });
      } catch (error) {
        alert("Sem conexão com o servidor");
      }
    }
    handleEmpresas();
  }, []);
  return (
    <div className={styles.container} {...rest}>
      <h1>Conheça nossos clientes</h1>
      <div className={styles.slider}>
        <div className={styles.slideTrack}>
          {lists.firstList.length > 0 &&
            lists.firstList.map((client) => (
              <Client key={client.NomeFantasia} client={client} />
            ))}
        </div>
      </div>
      <div className={styles.slider}>
        <div className={styles.slideTrack1}>
          {lists.secondList.length > 0 &&
            lists.secondList.map((client) => (
              <Client key={client.NomeFantasia} client={client} />
            ))}
        </div>
      </div>
    </div>
  );
}
