import React from "react";

import styles from "./styles.module.css";

const CitiesProps = [
  {
    name: "",
    local: {
      lat: 0,
      lon: 0,
      zoom: 5,
    },
  },
];

export function Cities({ cities = CitiesProps, handleCenter }) {
  return (
    <div className={styles.container}>
      {cities.map((city) => (
        <div className={styles.city} key={city.name} onClick={() => handleCenter(city.local)}>
          <h2 className={styles.cityName}>{city.name}</h2>
        </div>
      ))}
    </div>
  );
}
