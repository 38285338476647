import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Slider from "infinite-react-carousel";

import { unideskApi } from "../../../services/unidesk";

import { Employee } from "./Employee";
import debounce from "../../../utils/debouce";

export function Employees() {
  const [employees, setEmployees] = useState([]);
  const [slidesToShow, setSlidesToShow] = useState(
    Math.floor((window.innerWidth * 0.9) / 340)
  );

  useEffect(() => {
    const fetchUnideskUsers = async () => {

      const { data } = await unideskApi.get("Usuario/listarFilter.php");

      if (Array.isArray(data) && data.length > 0) {
        const newUsers = data.filter(
          (user) =>
            user.Ativo === "True" && !!user.Imagem && !!+user.showOnUnitech
        );
        setEmployees(newUsers);
      }
    };
    fetchUnideskUsers();
  }, []);

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setSlidesToShow(Math.floor((window.innerWidth * 0.9) / 340));
    }, 1000);
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>Conheça nossos colaboradores</h1>
        {employees.length > 0 && (
          <Slider
            autoplay
            arrows={false}
            slidesToShow={
              slidesToShow > 3 ? 3 : slidesToShow < 1 ? 1 : slidesToShow
            }
          >
            {employees.map((employee) => (
              <Employee key={employee.Nome} employee={employee} />
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}
