import React, { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";

import styles from "./styles.module.css";

export function ScrollTopButton({ ...rest }) {
  const [isVisible, setIsVisible] = useState(false);
  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 600) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <div
      className={`${styles.container} ${isVisible ? "" : styles.inactive}`}
      onClick={handleScrollTop}
      {...rest}
    >
      <FaChevronUp size={28} color="#ffffff" />
    </div>
  );
}
