import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOMClient.createRoot(
  document.getElementById('root')
);

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>

)

serviceWorker.unregister();
