const debounce = (fn, ms) => {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            fn.apply(this);
        }, ms);
    };
};

export default debounce;