import React from "react";
import styles from "./styles.module.css";

export function Privacy() {

    return (
        <div className={styles.container}>
            <div className={styles.navbarBackground} />
            <div className={styles.content}>
                <div className={styles.row}>
                    <div className={styles.bold}>Política de Privacidade</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>1.</div> Esta Política de Privacidade se refere aos dados pessoas que o(a) Menucheff  poderá obter quando seus Usuários utilizam dos
                    serviços prestados durante a navegação dentro de nosso ambiente virtual. Nesta Política de Privacidade, será descrita de que
                    forma serão coletados e armazenados dados a respeito dos Usuários, assim como a forma pela qual tais dados poderão ser utilizados
                    e armazenados nos termos da Lei 12.965/2014 (“Marco Civil da Internet”), do Decreto 8.771/2016 e da Lei n.º 13.709/2018 (Lei
                    Geral de Proteção de Dados).
                </div>

                <div className={styles.row}>
                    Ao aceitar a presente Política de Privacidade é declarado que todo seu conteúdo foi devidamente lido e aceito, tendo assim todas
                    as cláusulas devidamente validadas pelo Usuário para que atuem em pleno vigor.
                </div>

                <div className={styles.row}>
                    Ademais, deixa claro que esta Política de Privacidade poderá ser atualizada a qualquer tempo, a exclusivo critério dos administradores
                    do ambiente virtual aqui discutido. Porém, deixa claro que toda e qualquer comunicação será devidamente comunicada aos Usuários para
                    nova aceitação de modo de que, caso continuem em utilizar os serviços providenciados, estarão automaticamente concordando com as alterações
                    realizadas no corpo desta política.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>Obtenção, Armazenamento e Cuidado dos dados pessoais coletados.</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>2.</div> Aceitando nossa política de Privacidade, fica concedido, por livre e espontânea vontade, permissão para a coleta e armazenamento dos
                    dados pessoas dos Usuários, que serão tratados da forma abaixo descrita:
                </div>
                <div className={styles.containerTabRow}>
                    <div className={styles.tabRow}>
                        * Poderão ser coletados informações fornecidas no cadastro ao longo do uso dos Serviços e durante a sua navegação em nossa plataforma.
                    </div>
                    <div className={styles.tabRow}>
                        * Eventuais dados de navegação serão utilizados para possibilitar o seu acesso e saber como entrar em contato com o Usuário quando for necessário. Além de utilizados para compreender seus interesses, como forma de garantir constante melhoria e evolução dos serviços providenciados.
                    </div>
                    <div className={styles.tabRow}>
                        * Os dados pessoais, poderão ser armazenados em outros países onde o(a) Menucheff  ou suas afiliadas possuam presença. Caso tais jurisdições possuam diferentes leis de proteção de dados, fica estabelecido o compromisso de armazenar e cuidar dos dados de acordo com tais leis e a presente Política de Privacidade.
                    </div>
                    <div className={styles.tabRow}>
                        * Os Dados pessoais poderão ser utilizados de forma anônima para fins estatísticos e de controle e melhora dos nossos serviços.
                    </div>
                    <div className={styles.tabRow}>
                        * Serão estabelecidas medidas de segurança razoáveis para proteger todos os dados pessoais providenciados. Entretanto, lembramos que não existe uma medida de segurança 100% eficaz.
                    </div>
                    <div className={styles.tabRow}>
                        * Eventualmente, todos os dados coletados pelo Usuário durante o uso dos serviços prestados, poderão ser excluídos ao momento em que o Usuário desejar, dito isso, somos obrigados a manter os registros de conexão durante o prazo legal.
                    </div>
                    <div className={styles.tabRow}>
                        * Esta Política de Privacidade se aplica a todos os websites detidos pelo(a) Menucheff ou qualquer outra página, mídia social, ferramenta, software ou conteúdo de sua propriedade.
                    </div>
                </div>
                <div className={styles.row}>
                    a. Não
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>3.</div> O fato de usar o site e/ou serviço disponibilizado confirma o consentimento inequívoco e incondicional do Usuário com esta Política, incluindo os termos de processamento de seus dados pessoais. Na ausência de consentimento do usuário com esta política e os termos de processamento de seus dados pessoais, o Usuário deve parar de usar o site e/ou o serviço providênciados, reservando à administração o direito de impedir o acesso do referido Usuário.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>4.</div> Este ambiente é destinado a usuários com 18 (dezoito) anos de idade ou mais. Se você tem menos de 18 (dezoito) Ao concordar com esta política de Privacidade, você tem a capacidade legal necessária para cumprir e ficar vinculado por esta política de Privacidade.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>Responsabilidades e CompetênciasArmazenamento de Informações</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>5.</div> É reconhecida a natureza sensível e confidencial dos dados e demais informações obtidas e armazenadas. Assim fica estabelecido o compromisso de manter estas informações de natureza confidencial em sigilo, sem utilizá-las ou divulgá-las sem a autorização do Usuário, exceto nos termos previstos nos Termos de Uso e na Política de Privacidade, bem como nos limites necessários para a execução das obrigações contratuais e legais, assim como para a defesa dos interesses do(a) Menucheff e dos Usuários.
                </div>
                <div className={styles.row}>
                    Cuidado com as informações e uso de Cookies
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>6.</div> O Usuário concorda que o(a) Menucheff  poderá coletar, registrar, organizar, acumular, armazenar, atualizar, extrair, usar, transferir, incluindo transferência para outros países onde possua parceiros e/ou afiliadas, remover e destruir dados pessoais e outras informações.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>7.</div> Os atos descritos acima poderão ser processados de forma manual e/ou com o uso de automação. O presente consentimento é válido até a sua retirada das configurações do Usuário e/ou até que seja solicitado pelo Usuário de forma direta. A solicitação pode ser enviada por escrito para o endereço eletrônico: unicheffdelivery@gmail.com.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>8.</div> Dentro dos limites da legislação aplicável, o(a) Menucheff tem o direito de transferir as informações fornecidas pelo Usuário para terceiros.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>9.</div> No tratamento de dados pessoais, serão tomadas as medidas legais, técnicas e organizacionais necessárias para proteger os dados pessoais contra o acesso não autorizado ou acidental, destruição, modificação, bloqueio, cópia, disposição, distribuição de dados pessoais, bem como outras ações ilegais em relação a dados pessoais em cumprimento dos requisitos da legislação brasileira e/ou qualquer outra que venha a ser aplicável. O Usuário concorda que algumas das informações, incluindo dados pessoais, ficam disponíveis publicamente ao usar determinados serviços e/ou o site.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>10.</div> Menucheff faz uso de cookies. Ao acessar nosso site, você concorda em usar cookies nos termos da nossa Política de Cookies. Salienta-se que algum dos nossos parceiros de site podem usar cookies.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>Manutenção dos dados pelo Usuário</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>11.</div> O usuário tem o direito de requerer a exclusão de seus dados pessoais coletados durante o uso do ambiente eletrônico disponibilizado a qualquer momento, utilizando-se serviço relevante disponibilizado pelo próprio ambiente, ou por meio de contato direto com a administração por meio do endereço eletrônico disponibilizado acima.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>12.</div> Caso o Usuário tenha ciência de que seus dados pessoas tenham sido comprometidos, de forma que poderá afetar seu acesso ao ambiente eletrônico providênciado, ele deverá comunicar imediatamente a administração para que sejam tomadas todas a medidas de segurança necessárias.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>13.</div> O Usuário é o único responsável por suas ações relacionadas ao uso do site e/ou serviços disponibilizados, significando que, se tais ações resultarem em violação dos direitos e interesses legítimos de terceiros, bem como descumpre com a legislação do Brasil, o Usuário concorda em indenizar os prejuízos causados ao(à) Menucheff e/ou terceiros como resultado da não execução ou má execução das obrigações do Usuário sob esta Política e/ou a legislação.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>Responsabilidades e Competências</div>
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>14.</div> O Usuário usa os Serviços por sua conta e risco, sendo o único responsável por perdas incorridas como resultado do mal-uso pelo do ambiente e/ou dos serviços da Empresa.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>15.</div> O(A) Menucheff coopera com as autoridades competentes e com terceiros para garantir o cumprimento das leis, salvaguardar a integridade e a segurança da Plataforma e de seus usuários, e impedir condutas que prejudiquem a integridade moral e a honra das pessoas físicas ou jurídicas envolvidas.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>16.</div>. As disposições desta Política são regidas pelas leis do Brasil. Se, por uma razão ou outra, uma ou mais disposições desta Política forem consideradas inválidas, isso não afeta as demais disposições.
                </div>
                <div className={styles.row}>
                    <div className={styles.bold}>17.</div> Para todas as questões o Usuário pode enviar um pedido para o endereço da empresa para a seguinte conta: unicheffdelivery@gmail.com
                </div>
            </div>
        </div>
    );
}
