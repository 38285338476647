import React from "react";

import styles from "./styles.module.css";
import { Link } from "react-router-dom";

export function MobileNavModal({ isOpen, handleOpenNavMobile }) {
  const ListItem = ({ text = "", to = "/" }) => (
    <li className={styles.listItem}>
      <Link to={to} className={styles.link} onClick={handleOpenNavMobile}>
        {text}
      </Link>
    </li>
  );

  return (
    <div className={`${styles.container} ${isOpen ? styles.isOpen : ""}`}>
      <div className={styles.content}>
        <ul className={styles.list}>
          <ListItem text="Início" to="/" />
          <ListItem text="Onde Estamos" to="/Locais" />
          <ListItem text="Políticas de Privacidade" to="/privacy" />
        </ul>
      </div>
    </div>
  );
}
