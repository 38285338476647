import React, { useState } from "react";
import Maps from "./Maps";

import styles from "./styles.module.css";
import { Cities } from "./Cities";

import dataJson from "../../data/data.json";

const defaultCenter = {
  lat: -15.79674,
  lng: -47.898582,
};

const cities = dataJson.Cities;

export function Locations() {
  const [coordinates, setCoordinates] = useState({
    center: defaultCenter,
    zoom: 5,
  });

  const handleCenter = ({ lat = 0, lon = 0, zoom = 13 }) => {
    setCoordinates({ center: { lat: lat, lng: lon }, zoom });
  };

  return (
    <div className={styles.container}>
      <div className={styles.navbarBackground} />
      <div className={styles.content}>
        <Cities cities={cities} handleCenter={handleCenter} />
        <Maps cities={cities} center={coordinates.center} zoom={coordinates.zoom}/>
      </div>
    </div>
  );
}
