import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";

import styles from "./styles.module.css";

export function Marker({ text = '' }) {
  return (
    <div className={styles.container}>
      <FaMapMarkerAlt size={18} />
      <span style={{ color: "#ffffff" }}>{text}</span>
    </div>
  );
}
