import { Employees } from "./Employees";
import { Features } from "./Features";
import { Products } from "./Products";
import { OurClients } from "./OurClients";
import { Partnes } from "./Partnes";
import { Footer } from "../../components/Footer";
import { WhatsAppButton } from "../../components/WhatsAppButton";
import { ScrollTopButton } from "../../components/ScrollTopButton";

import styles from "./styles.module.css";

export function Home() {
  return (
    <div className={styles.container}>
      <Products />
      <Features />
      <OurClients />
      <Employees />
      <Partnes />
      <Footer />
      <ScrollTopButton />
      <WhatsAppButton />
    </div>
  );
};
