import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MobileNavModal } from "../MobileNavModal";

import styles from "./styles.module.css";

import logoUnitech from "../../assets/logoUnitech.png";

const ListLink = ({ to = "/", title = "" }) => (
  <li className={styles.listItem}>
    <Link to={to} className={styles.link}>
      {title}
    </Link>
  </li>
);

export function Navbar() {
  const [mobileModalOpen, setMobileModalOpen] = useState(false);

  const handleOpenNavMobile = (isOpen = false) => {
    const layout = window.document.getElementById("layout");
    if (isOpen) {
      layout.style.maxHeight = "100vh";
      layout.style.overflow = "hidden";
    } else {
      layout.style.maxHeight = "inherit";
      layout.style.overflow = "unset";
    }
    setMobileModalOpen(isOpen);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/" className={styles.link}>
          <div className={styles.logoWrapper}>
            <img src={logoUnitech} alt="" className={styles.logo} />
          </div>
        </Link>

        <div
          className={`${styles.navButton} ${mobileModalOpen ? styles.modalActive : ""
            }`}
          onClick={() => handleOpenNavMobile(!mobileModalOpen)}
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>

        <MobileNavModal
          isOpen={mobileModalOpen}
          handleOpenNavMobile={() => handleOpenNavMobile(!mobileModalOpen)}
        />

        <ul className={styles.list}>
          <ListLink to="/" title="Início" />
          <ListLink to="/locais" title="Onde Estamos" />
          <ListLink to="/privacy" title="Políticas de Privacidade" />
        </ul>
      </div>
    </div>
  );
}
